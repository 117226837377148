// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('channels');

require('bootstrap');
require('jquery');
require('jquery.easing');

require('chartkick')
require('chart.js')

import '@fortawesome/fontawesome-free/js/all';
import '../stylesheets/backoffice';

import 'select2'
import 'select2/dist/css/select2.css'

import '../stylesheets/application'
import toastr from 'toastr'
window.toastr = toastr

document.addEventListener('DOMContentLoaded', () => {
  // Backoffice Theme settings
  import('./theme/sb-admin-2');
  import("./backoffice/orders");
  import("./backoffice/lines");

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  $('a[id*="submission-approve"], a[id*="submission-reject"]').click(function () {
    var id = $(this).attr('id').split('-');
    var modal_id = `#${id[1]}-modal`;
    $(`${modal_id} form`).attr('action', `/statusmatch/backoffice/submissions/${id[2]}`);
  });

  // JS for all modal forms to approve orders

  $('.custom-approval-modal').on('shown.bs.modal', function () {
    var modal = $(this);
    var commentField = modal.find('.custom-comment-field');
    var selector = modal.find('.js-example-basic-single');

    selector.on('change', function() {
      var selectedOption = selector.val();
      
      if (selectedOption === 'OTRO') {
        commentField.prop('disabled', false);
        commentField.prop('required', true);
      } else {
        commentField.prop('disabled', true);
        commentField.prop('required', false);
        commentField.val('');
      }
    });
  });
});

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;